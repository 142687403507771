import { CSSProperties } from 'react';
import styles from './Loader.module.scss';

export interface LoaderProps {
  show?: boolean;
  colorOn?: string;
  colorOff?: string;
  dotSizePixels?: number;
}

export default function Loader({
  show = true,
  colorOn = '#fff',
  colorOff = 'rgba(255, 255, 255, 0.5)',
  dotSizePixels = 6,
}: LoaderProps) {
  return show ? (
    <div
      className={styles['dot-flashing']}
      style={
        {
          '--color-on': colorOn,
          '--color-off': colorOff,
          '--dot-size': `${dotSizePixels}px`,
        } as CSSProperties // Because the type is a dumb dumb about CSS variables
      }></div>
  ) : null;
}
