import { FetchWrapper } from './fetch-wrapper';

const API_URL = 'https://askme-server.tcd.io';
const API_VERSION = 'v1';
const API_BASE = `${API_URL}/${API_VERSION}`;

export interface QuestionQuery {
  id?: string;
  question: string;
  forceUpdate?: boolean;
}

export interface ContentBlock {
  id: string;
  score: number;
  blockContent: string;
  blockIndex: number;
  dateGmt: string;
  modifiedGmt: string;
  postId: number;
  title: string;
  type: string;
  url: string;
}

export interface QuestionResponse {
  responseText: string;
  closestBlocks: ContentBlock[];
}

export default class AskService {
  static askQuestion(question: string): Promise<QuestionResponse> {
    const query: QuestionQuery = {
      id: 'managing-editor',
      question,
      forceUpdate: false,
    };
    return FetchWrapper.post(`${API_BASE}/ask`, query);
  }
}
