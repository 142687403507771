import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Link,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import AskService, { QuestionResponse } from '../../services/ask-service';
import Loader from '../Loaders/Loader';

interface AskFormData {
  question: string;
}

export default function AskForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AskFormData>();
  const [isLoading, setIsLoading] = useState(false);
  const [questionResponse, setQuestionResponse] = useState<QuestionResponse | null>(null);
  const [apiError, setApiError] = useState<string | null>(null);

  const onSubmit: SubmitHandler<AskFormData> = async (data) => {
    try {
      const { question } = data;
      console.log(question);
      setIsLoading(true);
      setQuestionResponse(null);
      setApiError(null);
      const apiResponse = await AskService.askQuestion(question);
      setQuestionResponse(apiResponse);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setApiError((error as Error).message);
    }
  };

  let amConfidentInAnswer = false;
  if (questionResponse && questionResponse?.closestBlocks[0].score > 0.79) {
    amConfidentInAnswer = true;
  }
  const answerHeading = amConfidentInAnswer ? 'Answer' : 'Possible Answer';
  const sourceHeading = amConfidentInAnswer ? 'Source' : 'Possible Source';

  return (
    <Container>
      <Stack>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack>
            <FormControl isInvalid={!!errors.question}>
              <FormLabel>Enter Your Question</FormLabel>
              <Textarea
                placeholder="E.g. How can I use AI to improve my content marketing?"
                {...register('question', {
                  required: 'Question is required',
                })}
              />
              {errors.question ? (
                <FormErrorMessage>{errors.question.message}</FormErrorMessage>
              ) : null}
            </FormControl>
            <div>
              <Button colorScheme="blue" type="submit" disabled={isLoading}>
                {isLoading ? <Loader /> : <span>Ask</span>}
              </Button>
            </div>
          </Stack>
        </form>
        {apiError ? (
          <Box>
            <Text>Oops, there was a problem. Please try again.</Text>
          </Box>
        ) : null}
        {isLoading ? (
          <Box>
            <span>Searching for an answer... </span>
            <Loader />
          </Box>
        ) : null}
        {questionResponse ? (
          <Box pt="4">
            <Stack gap="2">
              {!amConfidentInAnswer ? (
                <Box bg="yellow.100" p="2">
                  <Text>
                    <strong>Warning:</strong> I’m not very confident in this answer. It might not be
                    the best answer for you.
                  </Text>
                </Box>
              ) : null}
              <Box>
                <Heading size="sm">{answerHeading}</Heading>
                <Text>{questionResponse.responseText}</Text>
              </Box>
              <Box>
                <Heading size="sm">{sourceHeading}</Heading>
                <Link
                  textDecoration="underline"
                  href={questionResponse.closestBlocks[0].url}
                  isExternal>
                  {questionResponse.closestBlocks[0].title} <ExternalLinkIcon mx="2px" />
                </Link>
              </Box>
            </Stack>
          </Box>
        ) : null}
      </Stack>
    </Container>
  );
}
