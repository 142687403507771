import axios, { AxiosError } from 'axios';

export class FetchWrapper {
  static async get(url: string) {
    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static async post(url: string, body: any) {
    try {
      const response = await axios.post(url, body);
      return response.data;
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static async put(url: string, body: any) {
    try {
      const response = await axios.put(url, body);
      return response.data;
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static async patch(url: string, body: any) {
    try {
      const response = await axios.patch(url, body);
      return response.data;
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  static async delete(url: string) {
    const response = await fetch(url, {
      method: 'DELETE',
    });
    return response.json();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private static handleError(error: any) {
    const axiosError = error as AxiosError;
    console.error(axiosError);
  }
}

// function AuthHeader(url: string) {
//   const isApiUrl =
//     process.env.NEXT_PUBLIC_API_URL && url.startsWith(process.env.NEXT_PUBLIC_API_URL);
//   if (!isApiUrl) {
//     return {};
//   }
//   const token = Auth?.loginData?.token;
//   if (!token) {
//     return {};
//   }
//   return { Authorization: 'Bearer ' + token };
// }
